import { isAuthError, isUnauthorizedError } from '@/helpers/globalErrorHelper'

const LOGGER_NAME = 'ErrorHandler.js'

class ErrorHandler {
  store
  router
  logger
  donationSpec

  authErrorHandlingInProgress = false

  constructor (store, router, logger, donationSpec) {
    this.store = store
    this.router = router
    this.logger = logger
    this.donationSpec = donationSpec
  }

  handleAuthError = async (payload) => {
    if (this.logger) {
      this.logger.info(LOGGER_NAME, payload)
    }
    if (isAuthError(payload.error)) {
      if (this.authErrorHandlingInProgress) {
        return true
      }
      this.authErrorHandlingInProgress = true
      if (isUnauthorizedError(payload.error)) {
        await this.store.dispatch('auth/logout', false)
      }
      // In order to simplify reset state logic we navigate user's browser to index location with sessionExpired query param.
      // Also, we have to preserve any existing query params using `window.location.search`
      const queryString = window.location.search ? window.location.search + '&sessionExpired=true' : '?sessionExpired=true'
      window.location.assign(`/donation/${this.donationSpec.metadata.id}${queryString}`)
      return true
    }
    return false
  }

  handleError = async (error, vm, info = 'global error handler') => {
    this.store.commit('progressLoader/resetState')
    if (!await this.handleAuthError({ error, vm, info })) {
      this.logger.error(LOGGER_NAME, { error, vm, info })
    }
  }

  /**
   * only invoked during development
   * @param error
   * @param vm
   * @param info
   */
  handleWarning = (error, vm, info = 'global error handler') => {
    this.logger.warn(LOGGER_NAME, { error, vm, info })
  }
}

export default ErrorHandler
