import { ProductType } from '@/helpers/ProductType.constants'

export const orderStateFn = () => ({
  customer: {
    id: '',
    firstName: '',
    lastName: '',
    address: {
      address: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      zip: ''
    }
  },
  cartItemSku: '',
  order: {
    id: '',
    items: [
      {
        id: '',
        quantity: 1,
        sku: '',
        total: {
          amount: 0,
          currencyCode: '',
          prettyPrint: ''
        },
        product: {
          productType: ''
        }
      }
    ],
    total: {
      amount: 0,
      currencyCode: '',
      prettyPrint: ''
    }
  },
  financialInstrument: {
    creditCard: {
      expiration: '',
      lastFour: '',
      type: ''
    },
    financialInstrumentType: ''
  }
})

export const mutations = {
  state (state, payload) {
    Object.assign(state, payload)
  },
  cartItems (state, payload) {
    Object.assign(state.cartItems, payload)
  },
  resetState (state) {
    Object.assign(state, orderStateFn())
  }
}

export const getters = {
  isEmpty (state) {
    return state.order.total.amount === 0
  },
  total (state) {
    return state.order.total.amount
  },
  orderId (state) {
    return state.order.id
  },
  cartItemSku (state) {
    return state.cartItemSku
  },
  currency (state) {
    return state?.order?.total?.currencyCode
  },
  cartItems (state) {
    return state.order.items
  },
  donationItems (state) {
    return state.order?.items?.filter(orderItem => orderItem.product?.productType === ProductType.DONATION)
  },
  messageContext (state) {
    const customer = state.customer
    const total = state.order?.total?.prettyPrint?.substring(1)
    return {
      customer,
      order: { total }
    }
  },
  financialInstrumentType (state) {
    return state.financialInstrument?.financialInstrumentType
  },
  paymentType (state) {
    const financialInstrumentType = state.financialInstrument.financialInstrumentType
    if (financialInstrumentType === 'CREDIT_CARD') {
      return 'creditcard'
    }
    if (financialInstrumentType === 'PAYPAL') {
      return 'paypal'
    }
    if (financialInstrumentType === 'APPLEPAY') {
      return 'applepay'
    }
    if (financialInstrumentType === 'ACH') {
      return 'bank'
    }
    if (financialInstrumentType === 'AMAZONPAY') {
      return 'amazonpay'
    }
  }
}

export default {
  namespaced: true,
  state: orderStateFn,
  mutations,
  getters
}
