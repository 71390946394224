import { normalizeMap, regionReducer } from '@/helpers'

const createCountry = () => ({
  countryCode: '',
  countryName: '',
  regions: [],
  regionsList: []
})

export const state = {
  US: createCountry(),
  CA: createCountry(),
  stateCityByZip: {}
}

export const actions = {
  async countryRegions ({ dispatch, commit, state, rootGetters }, { country }) {
    const countryRegion = state[country]
    if (countryRegion && countryRegion.countryCode) {
      return countryRegion
    }
    const sessionToken = rootGetters['auth/sessionToken']
    const { data } = await this.$api.countriesApi.fetchCountryByCode(sessionToken, country)
    commit('countryRegions', { [country]: data })
    return data
  },
  async stateCityByZipCode ({ dispatch, state, commit }, { zipCode }) {
    const storedValue = state.stateCityByZip[zipCode]
    if (storedValue && storedValue.status && storedValue.status !== 'ERROR') {
      return storedValue
    }

    const apiResponse = {}
    try {
      const result = await this.$api.stateCityApi.lookupCityStateByZipCode(zipCode)
      apiResponse.status = 'FOUND'
      apiResponse.data = {
        city: result.data.city,
        state: result.data.state
      }
      commit('stateCityByZip', { [zipCode]: apiResponse })
    } catch (e) {
      if (e.response?.status === 404) {
        apiResponse.status = 'NOT_FOUND'
      } else {
        this.$logger.error('geographies.js', {
          error: e,
          info: 'lookupCityStateByZipCode failed. User should not be blocked. But stateCityApi is not available'
        })
        apiResponse.status = 'ERROR'
      }
      commit('stateCityByZip', { [zipCode]: apiResponse })
    }
    return apiResponse
  }
}

export const mutations = {
  countryRegions (state, payload) {
    Object.assign(state, payload)
  },
  stateCityByZip (state, payload) {
    Object.assign(state.stateCityByZip, payload)
  }
}

export const getters = {
  getStateCityByZip: (state) => (zip) => {
    const { stateCityByZip } = state
    return stateCityByZip[zip] || {}
  },
  getRegionsList: (state) => (country) => {
    if (!country || !state[country]) return []
    const { regions } = state[country]
    if (!regions) return []
    return normalizeMap(regions.reduce(regionReducer, {}))
  }
}

const module = {
  namespaced: true,
  state: () => state,
  actions,
  mutations,
  getters
}

export default module
