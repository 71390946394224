import {
  convertDonationItemToGtmEcommerceItem
} from './gtmGa4DataHelper'

class DonationFlowGtmGA4EcommerceOptionsBuilder {
  withBeginCheckoutEvent () {
    this.gtmOptions['event'] = 'begin_checkout'
    return this
  }

  withAddPaymentInfoEvent () {
    this.gtmOptions['event'] = 'add_payment_info'
    return this
  }

  withPurchaseEvent () {
    this.gtmOptions['event'] = 'purchase'
    return this
  }

  withCartEcommerceInfo (total = {}, donationItems = []) {
    // From https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm
    // We should have `ecommerce` object for event
    this.gtmOptions['ecommerce'] = {
      currency: total.currencyCode,
      value: total.amount,
      items: donationItems.map(convertDonationItemToGtmEcommerceItem)
    }
    return this
  }

  withPurchaseEcommerceInfo (orderNumber, paymentType, total = {}, donationItems = []) {
    // From https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm
    // We should have `ecommerce` object for event
    this.gtmOptions['ecommerce'] = {
      currency: total.currencyCode,
      value: total.amount,
      payment_type: paymentType, // Example 'CREDIT_CARD'
      transaction_id: orderNumber, // Example '114666873'
      items: donationItems.map(convertDonationItemToGtmEcommerceItem)
    }
    return this
  }

  build () {
    return this.gtmOptions
  }

  constructor () {
    this.gtmOptions = {}
  }
}
export default DonationFlowGtmGA4EcommerceOptionsBuilder
