import StopWatch from '@/helpers/StopWatch'

/**
 * Initializes stopwatch utility object to track duration.
 *
 * @return {Object} StopWatch an instance of StopWatch.
 */
export const initializeStopWatch = function () {
  return new StopWatch()
}

const plugin = {
  install (Vue, stopWatch) {
    if (plugin.installed) {
      return
    }
    plugin.installed = true
    Vue.prototype.$stopWatch = stopWatch
  }
}
export default plugin
