import AskAmountFixedInputAdapter from '@/adapters/AskAmountFixedInputAdapter'
import MemberBenefitsCopyAdapter from '@/adapters/MemberBenefitsCopyAdapter'
import AskAmountOtherInputAdapter from '@/adapters/AskAmountOtherInputAdapter'

export default class AskContributionAdapter {
  constructor (cmsData) {
    this.cmsData = cmsData
  }

  adapt () {
    const model = new AskContributionModel()
    model.amounts = this.cmsData.amounts.map((cmsAmt, index) => {
      const askAmountFixedInputAdapter = new AskAmountFixedInputAdapter(cmsAmt, index).adapt()
      if (cmsAmt.default) {
        model.selectedAmountIndex = index
      }
      return askAmountFixedInputAdapter
    })
    // Add 'other' field
    const otherField = new AskAmountOtherInputAdapter({
      label: 'other',
      value: ''
    }, model.amounts.length).adapt()
    model.amounts.push(otherField)
    model.memberBenefits = new MemberBenefitsCopyAdapter(this.cmsData['donor-benefits']).adapt()
    model.showMemberBenefits = false
    return model
  }
}

export class AskContributionModel {
  amounts
  selectedAmountIndex
  memberBenefits
  showMemberBenefits
}
