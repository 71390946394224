import UpgradeContentAdapter from '@/adapters/UpgradeContentAdapter'

export default class UpgradeOverlayAdapter {
  constructor (cmsData) {
    this.cmsData = cmsData
  }

  adapt () {
    const model = new UpgradeOverlayModel()
    model.template = this.cmsData['template']
    model.type = this.cmsData['type']
    model.value = this.cmsData['value']
    model.upgradeContent = new UpgradeContentAdapter(this.cmsData['content']).adapt()
    model.enabled = true
    return model
  }
}

export class UpgradeOverlayModel {
  enabled
  template
  type
  value
  upgradeContent
}
