const plugin = {
  install (Vue, options) {
    if (plugin.installed) {
      return
    }
    plugin.installed = true
    Vue.prototype.$configuration = Object.freeze(options)
  }
}
export default plugin
