import {
  MONTHLY_CONTRIBUTION_SKU_REGEXP,
  ONETIME_CONTRIBUTION_SKU_REGEXP
} from '@/helpers/skuUtils'
import { assert } from '@/helpers'

export default class ContributionFrequencyAdapter {
  constructor (cmsData) {
    this.cmsData = cmsData
  }

  convertSkuCodeToFrequencyType (skuCode) {
    assert(skuCode != null, 'sku cannot be null')
    if (MONTHLY_CONTRIBUTION_SKU_REGEXP.test(skuCode)) {
      return 'monthly'
    } else if (ONETIME_CONTRIBUTION_SKU_REGEXP.test(skuCode)) {
      return 'one_time'
    } else {
      assert(false, 'unsupported sku code: ' + skuCode)
    }
  }

  adapt () {
    const model = new ContributionFrequencyModel()
    model.skuCode = this.cmsData['sku-code']
    model.label = this.cmsData['frequency-label']
    model.frequencyType = this.convertSkuCodeToFrequencyType(model.skuCode)
    model.selected = this.cmsData.default
    return model
  }
}

export class ContributionFrequencyModel {
  skuCode
  label
  selected
  frequencyType
}
