export default class ConditionalMessagePredicateAdapter {
  constructor (predicateDataList = []) {
    this.predicateDataList = predicateDataList
  }

  adapt () {
    return this.predicateDataList.map(PredicateFactory.createPredicate)
  }
}

/**
 * Base class for predicates.
 */
export class Predicate {
  /**
   * @property {string} name - The name of the predicate.
   */
  name
  /**
   * @property {Array<any>} args - The arguments of the predicate.
   */
  args

  /**
   * @param {string} name - The name of the predicate.
   * @param {Array<any>} args - The arguments of the predicate.
   */
  constructor (name = 'Base Predicate', args = []) {
    this.name = name
    this.args = args
  }

  /**
   * Tests if the predicate applies to the given context. Throws an error if not implemented.
   * @param context
   * @returns {boolean}
   */
  test (context) {
    throw new Error('test method not implemented')
  }
}

class Comparator {
  compareFn
  constructor (fn) {
    this.compareFn = fn
  }

  evaluate (a, b) {
    return this.compareFn(a, b)
  }
}

export class OrderTotalPredicate extends Predicate {
  constructor (name, args, comparator) {
    super(name, args)
    if (isNaN(args[0])) {
      throw Error('OrderTotalPredicate: argument is not a number')
    }
    this.value = parseFloat(args[0])
    this.comparator = comparator
  }

  test (context) {
    const total = context?.order?.total
    if (!total) {
      return false
    }
    return this.comparator.evaluate(total, this.value)
  }
}

export const greaterThanComparator = new Comparator((a, b) => a > b)
export const greaterThanOrEqualComparator = new Comparator((a, b) => a >= b)
export const lessThanComparator = new Comparator((a, b) => a < b)
export const lessThanOrEqualComparator = new Comparator((a, b) => a <= b)

export class PredicateFactory {
  static createPredicate (predicateData) {
    const { name, arguments: args } = predicateData
    switch (name) {
      case 'ORDER_TOTAL_GREATER_THAN':
        return new OrderTotalPredicate(name, args, greaterThanComparator)
      case 'ORDER_TOTAL_GREATER_THAN_OR_EQUAL':
        return new OrderTotalPredicate(name, args, greaterThanOrEqualComparator)
      case 'ORDER_TOTAL_LESS_THAN':
        return new OrderTotalPredicate(name, args, lessThanComparator)
      case 'ORDER_TOTAL_LESS_THAN_OR_EQUAL':
        return new OrderTotalPredicate(name, args, lessThanOrEqualComparator)
      default:
        throw new Error('Unsupported predicate type: ' + name)
    }
  }
}
