import { cookies } from '@/configuration'

export const isCsrImpersonation = () => {
  return !!(cookies.csrImpersonatedUserGuid && cookies.csrUserToken && cookies.userInfo)
}

export const applySuffixIfCsr = (value) => {
  if (isCsrImpersonation()) {
    value += '-CSR'
  }
  return value
}
