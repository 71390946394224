export default class ImageAdapter {
  constructor (cmsData) {
    this.cmsData = cmsData
  }

  adapt () {
    const model = new ImageModel()
    if (this.cmsData) {
      model.alt = this.cmsData.alt
      model.url = this.cmsData.url
      model.publicId = this.cmsData['public-id']
    }
    return model
  }
}

export class ImageModel {
  alt
  url
  publicId
}
