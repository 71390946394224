import { rethrowIfAuthError } from '@/helpers/globalErrorHelper'

export const INVALID_BILLING_ADDRESS = 'INVALID_BILLING_ADDRESS'
export const PURCHASE_EXCEPTION = 'PURCHASE_FAILED'
export const CART_ITEM_PURCHASABILITY_ERROR = 'CART_ITEM_PURCHASABILITY_ERROR'

export const errorToCustomer = (error) => {
  const city = error.address.city
  const country = error.address.country
  const address = error.address.streetAddress
  const stateR = error.address.state
  const zip = error.address.zipCode
  const firstName = error.address.firstName
  const lastName = error.address.lastName
  const customerAddress = { city, country, address, state: stateR, zip }
  return { firstName, lastName, address: customerAddress }
}

export const handleCheckoutError = (error, { firstName, lastName, address }, logger, loggerName) => {
  rethrowIfAuthError(error)
  logger.error(loggerName, { error, info: 'checkout failed' })
  const { response } = error
  if (response?.status === 400 && response.data.errors && response.data.errors[0].code === CART_ITEM_PURCHASABILITY_ERROR) {
    throw Object({
      code: PURCHASE_EXCEPTION, data: CART_ITEM_PURCHASABILITY_ERROR
    })
  } else if (response?.status === 400 && response?.data?.errors[0]?.code !== '92908') {
    if (response.data.errors[0].data != null) {
      throw Object({
        code: INVALID_BILLING_ADDRESS, data: errorToCustomer(response.data.errors[0].data)
      })
    } else {
      throw Object({
        code: INVALID_BILLING_ADDRESS, data: { firstName, lastName, address }
      })
    }
  } else {
    throw Object({
      code: PURCHASE_EXCEPTION, data: 'Purchase failed'
    })
  }
}
