export const progressLoaderStateFn = () => ({
  showCounter: 0,
  pauseCounter: 0
})

export const actions = {
  show ({ commit }) {
    commit('incrementShow')
  },
  hide ({ commit }) {
    commit('decrementShow')
  },
  pause ({ commit }) {
    commit('incrementPause')
  },
  resume ({ commit }) {
    commit('decrementPause')
  }
}

export const mutations = {
  incrementShow (state) {
    state.showCounter++
  },
  decrementShow (state) {
    state.showCounter--
  },
  incrementPause (state) {
    state.pauseCounter++
  },
  decrementPause (state) {
    state.pauseCounter--
  },
  resetState (state) {
    Object.assign(state, progressLoaderStateFn())
  }
}

export const getters = {
  isVisible (state) {
    return state.showCounter > 0 && state.pauseCounter <= 0
  }
}

const module = {
  namespaced: true,
  state: progressLoaderStateFn,
  actions,
  mutations,
  getters
}

export default module
