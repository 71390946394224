export const walletStateFn = () => ({
  paymentMethods: [
    {
      id: '',
      tokenType: '',
      expiry: '',
      lastFour: ''
    }
  ],
  selectedPaymentMethodId: ''
})

export const actions = {
  async init ({ commit }, { auth }) {
    if (auth.customer.id) {
      const { data } = await this.$api.walletApi.fetchCustomerWallet(auth.sessionToken, auth.customer.id)
      commit('paymentMethods', data.paymentMethods)
    }
  }
}

export const mutations = {
  paymentMethods (state, payload) {
    if (payload) {
      state.paymentMethods = payload
    }
  },
  selectedPaymentMethodId (state, payload) {
    state.selectedPaymentMethodId = payload
  },
  resetState (state) {
    Object.assign(state, walletStateFn())
  }
}

export const getters = {
  paymentMethods (state) {
    return state.paymentMethods.filter(paymentMethod => paymentMethod.id)
  },
  selectedPaymentMethod (state) {
    return state.paymentMethods.find(paymentMethod => paymentMethod.id === state.selectedPaymentMethodId)
  }
}

export default {
  namespaced: true,
  state: walletStateFn,
  actions,
  mutations,
  getters
}
