import ConditionalMessagePredicateAdapter from '@/adapters/ConditionalMessagePredicateAdapter'
import { LoggerFactory } from '@/plugins/logger'
const LOGGER_NAME = 'ConfirmationMessageAdapter.js'

export default class ConfirmationMessageAdapter {
  constructor (cmsData) {
    this.cmsData = cmsData
    this.$logger = LoggerFactory.getInstance()
  }

  adapt () {
    const defaultMessage = new DefaultMessageModel(
      this.cmsData['confirmation-message']
    )

    const conditionalMessageData = this.cmsData['conditional-messages'] || []
    const conditionalMessages = conditionalMessageData.reduce((accumulator, msg) => {
      try {
        const predicatesAdapter = new ConditionalMessagePredicateAdapter(msg['predicates'])
        const predicates = predicatesAdapter.adapt()
        accumulator.push(new ConditionalMessageModel(msg['confirmation-message'], predicates))
      } catch (error) {
        const message = 'Error while parsing conditional message'
        this.$logger.warn(LOGGER_NAME, { error, info: { message, conditionalMessage: msg } })
      }
      return accumulator
    }, [])
    return new ConfirmationMessageModel(defaultMessage, conditionalMessages)
  }
}

export class DefaultMessageModel {
  confirmationMessage

  constructor (confirmationMessage = '') {
    this.confirmationMessage = confirmationMessage
  }
}

export class ConditionalMessageModel extends DefaultMessageModel {
  predicates

  constructor (confirmationMessage = '', predicates = []) {
    super(confirmationMessage)
    this.predicates = predicates
  }

  applyTo (context) {
    return this.predicates.every(predicate => predicate.test(context))
  }
}

export class ConfirmationMessageModel {
  defaultMessage
  conditionalMessages

  constructor (defaultMessage = new DefaultMessageModel(), conditionalMessages = []) {
    this.defaultMessage = defaultMessage
    this.conditionalMessages = conditionalMessages
  }

  getConfirmationMessage (context = {}) {
    const applicableMessage = this.conditionalMessages.find(message => message.applyTo(context))
    return applicableMessage ? applicableMessage.confirmationMessage : this.defaultMessage.confirmationMessage
  }
}
