import { mapObjectProperties } from '@/helpers'

export class QueryParamFrequencySelectedAdapter {
  constructor (urlParams) {
    this.urlParams = urlParams
    this.optionMap = {
      frequencySelected: 'frequency_selected'
    }
  }

  adapt () {
    return mapObjectProperties(this.urlParams, this.optionMap)
  }
}
