export default class MetadataAdapter {
  constructor (cmsData) {
    this.cmsData = cmsData
  }

  adapt () {
    const model = new MetadataModel()
    model.id = this.cmsData.id
    model.revision = this.cmsData.revision
    model.templateName = this.cmsData['template-name']
    model.published = this.cmsData.published
    model.processingFeeEnabled = this.cmsData['processing-fee-enabled']
    return model
  }
}

export class MetadataModel {
  id
  revision
  templateName
  published
  processingFeeEnabled
}
