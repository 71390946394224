import { currentWindow } from '@/plugins/windows'

export const state = {
  uid: '',
  consent: {
    financialInstrumentType: '',
    country: '',
    region: '',
    consents: [
      {
        type: '',
        required: '',
        text: ''
      }
    ]
  }
}

const FINANCIAL_INSTRUMENT_TYPE = 'ACH'

export const actions = {
  async init ({ dispatch, commit, state }, { auth, cart, captchaData }) {
    const { hpfAchCallbackCss, hpfCallbackPageUrl } = this.$configuration.appConfig
    const initCommand = {
      cssUrl: hpfAchCallbackCss,
      callbackUrl: hpfCallbackPageUrl
    }
    if (captchaData) {
      initCommand.captchaResponse = {
        response: captchaData.response,
        ekey: captchaData.key,
        host: currentWindow.location.host,
        siteKey: captchaData.siteKey
      }
    }
    const { data } = await this.$api.cartsApi.achInitialization(auth.sessionToken, cart.id, initCommand)
    const response = { uid: data.uid }
    commit('state', response)
    return response
  },
  async consents ({ commit, state }, { auth, cart }) {
    const cartConsentsCommand = {
      financialInstrumentType: FINANCIAL_INSTRUMENT_TYPE
    }
    const { data: consent } = await this.$api.cartsApi.fetchCartConsents(auth.sessionToken, cart.id, cartConsentsCommand)
    commit('consent', consent)
    return consent
  }
}

export const mutations = {
  state (state, payload) {
    Object.assign(state, payload)
  },
  consent (state, payload) {
    Object.assign(state.consent, payload)
  }
}

export const filterAchConsent = (consent) => consent.consents.filter(consent => consent.type === FINANCIAL_INSTRUMENT_TYPE).map(({ type }) => type)

export const getters = {
  consents (state) {
    return filterAchConsent(state.consent)
  }
}

export default {
  namespaced: true,
  state: () => state,
  actions,
  mutations,
  getters
}
