import AskContributionAdapter from '@/adapters/AskContributionAdapter'
import ConfirmationMessageAdapter from '@/adapters/ConfirmationMessageAdapter'
import ContributionFrequencyAdapter from '@/adapters/ContributionFrequencyAdapter'
import UpgradeOverlayAdapter from '@/adapters/UpgradeOverlayAdapter'

export default class ContributionAdapter {
  constructor (cmsData, index) {
    this.cmsData = cmsData
    this.index = index
  }

  adapt () {
    const model = new ContributionModel()
    model.contributionFrequency = new ContributionFrequencyAdapter(this.cmsData).adapt()
    model.askContribution = new AskContributionAdapter(this.cmsData).adapt()
    model.emailMessage = this.cmsData['email-message']
    model.confirmationMessage = new ConfirmationMessageAdapter(this.cmsData).adapt()
    if (this.cmsData['upgrade']) {
      model.upgrade = new UpgradeOverlayAdapter(this.cmsData['upgrade']).adapt()
    }
    model.index = this.index
    return model
  }
}

export class ContributionModel {
  index
  contributionFrequency
  askContribution
  emailMessage
  confirmationMessage
  upgrade

  getConfirmationMessage (context = {}) {
    return this.confirmationMessage.getConfirmationMessage(context)
  }
}
