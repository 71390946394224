import { currentWindow } from '@/plugins/windows'

export default class Newrelic {
  constructor (agent = currentWindow.newrelic) {
    this.agent = agent
  }

  addPageAction (name, attributes) {
    this.log('addPageAction', name, attributes)
  }

  noticeError (error, attributes) {
    this.log('noticeError', error, attributes)
  }

  /**
   * delegate call to actual api if agent (window.newrelic) is not null
   * @param methodName
   * @param args
   */
  log (methodName, ...args) {
    if (!this.agent) return
    const method = this.agent[methodName]
    method.apply(this.agent, args)
  }
}
