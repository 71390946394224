export default class AskAmountOtherInputAdapter {
  constructor (data, index) {
    this.data = data
    this.index = index
  }

  adapt () {
    const model = new AskAmountOtherInputModel()
    model.label = this.data.label
    model.value = this.data.value
    model.index = this.index
    return model
  }
}

export class AskAmountOtherInputModel {
  index
  label
  value
}
