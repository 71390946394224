import { assert } from '@/helpers/index'
import { applySuffixIfCsr } from '@/helpers/impersonationHelper'

export const MONTHLY_SKU_CODE_NAME = 'Monthly'
export const ONE_TIME_SKU_CODE_NAME = 'One Time'

export const ONETIME_CONTRIBUTION_SKU_REGEXP = /^DONATION-VARIABLE-AMT.*-NR$/
export const MONTHLY_CONTRIBUTION_SKU_REGEXP = /^DONATION-VARIABLE-AMT.*1-MONTH-SUB$/

export const convertSkuCodeToPageType = (skuCode, store) => {
  skuCode = skuCode || store.getters['cart/cartItemSku'] || store.getters['order/cartItemSku']
  assert(skuCode != null, 'cart sku item cannot be null')
  if (MONTHLY_CONTRIBUTION_SKU_REGEXP.test(skuCode)) {
    return applySuffixIfCsr(MONTHLY_SKU_CODE_NAME)
  } else if (ONETIME_CONTRIBUTION_SKU_REGEXP.test(skuCode)) {
    return applySuffixIfCsr(ONE_TIME_SKU_CODE_NAME)
  } else {
    assert(false, 'unsupported sku code: ' + skuCode)
  }
}
