import { assert, isMobileBrowser } from '@/helpers'
import ImageAdapter from '@/adapters/ImageAdapter'

const memberCardTypeComponents = {
  fundraising: 'FundraisingMemberCard',
  'leadership-circle': 'LeadershipCircleMemberCard'
}

export default class HeroAdapter {
  constructor (cmsData) {
    this.cmsData = cmsData
  }

  adapt () {
    return new HeroModelFactory().createModel(this.cmsData)
  }
}

class HeroModelFactory {
  createModel (cmsData) {
    const heroType = cmsData.type
    if (heroType === 'hero-with-image') {
      return this.createHeroWithImageModel(cmsData)
    } else if (heroType === 'hero-with-member-card') {
      return this.createHeroWithMemberCardModel(cmsData)
    }
  }

  createHeroWithImageModel (cmsData) {
    const model = new HeroWithImageModel()
    this.populateCommonProperties(model, cmsData)
    model.componentType = 'HeroWithImage'
    if (isMobileBrowser()) {
      model.image = new ImageAdapter(cmsData['mobile-image']).adapt()
    } else {
      model.image = new ImageAdapter(cmsData['desktop-image']).adapt()
    }
    return model
  }

  createHeroWithMemberCardModel (cmsData) {
    const model = new HeroWithMemberCardModel()
    this.populateCommonProperties(model, cmsData)
    model.componentType = 'HeroWithMemberCard'
    const memberCardType = cmsData['member-card-type']
    if (memberCardType) {
      model.memberCardTypeComponent = memberCardTypeComponents[memberCardType.toLowerCase()]
      assert(model.memberCardTypeComponent, 'unsupported member card type: ' + memberCardType)
    }
    return model
  }

  populateCommonProperties (model, cmsData) {
    model.heading = cmsData?.copy?.heading
    model.body = cmsData?.copy?.body
  }
}

class HeroModel {
  heading
  body
  componentType
}

export class HeroWithImageModel extends HeroModel {
  image
}

export class HeroWithMemberCardModel extends HeroModel {
  memberCardTypeComponent
}
