import { assert } from '@/helpers'

const benefitModals = {
  'one-time': 'DonationModalMemberBenefits',
  monthly: 'DonationModalMemberChampion',
  'presidents-circle': 'DonationModalMemberCircle',
  'one-time-18-months': 'DonationModal18MonthBenefits'
}

export default class MemberBenefitsCopyAdapter {
  constructor (cmsData) {
    this.cmsData = cmsData
  }

  adapt () {
    const model = new MemberBenefitsCopyModel()
    model.message = this.cmsData.message
    model.type = this.cmsData.type
    model.typeComponent = benefitModals[model.type.toLowerCase()]
    assert(model.typeComponent, 'unsupported benefit modal type: ' + model.type)
    return model
  }
}

export class MemberBenefitsCopyModel {
  message
  type
  typeComponent
}
