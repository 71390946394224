import ImageAdapter from '@/adapters/ImageAdapter'

export default class UpgradeContentAdapter {
  constructor (cmsData) {
    this.cmsData = cmsData
  }

  adapt () {
    const model = new UpgradeContentModel()
    model.heading = this.cmsData['heading']
    model.bodyContent = this.cmsData['body']
    model.upgradeActionText = this.cmsData['upgrade-action-text']
    model.noUpgradeActionText = this.cmsData['no-upgrade-action-text']
    model.image = new ImageAdapter(this.cmsData['image']).adapt()
    return model
  }
}

export class UpgradeContentModel {
  heading
  bodyContent
  upgradeActionText
  noUpgradeActionText
  image
}
