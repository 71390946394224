import { WITH_CREDENTIALS_OPTION } from '@/store/constants'
import { createCommonCheckoutCommand } from '@/store/checkoutUtils'
import { currentWindow } from '@/plugins/windows'

export const state = {
  uid: '',
  metadata: {
    expiration: '',
    lastFour: '',
    type: ''
  }
}
export const actions = {
  async init ({ dispatch, commit, state }, { auth, cart, captchaData }) {
    const { hpfCreditCardCallbackCss, hpfCallbackPageUrl } = this.$configuration.appConfig
    const creditCardInitCommand = {
      cssUrl: hpfCreditCardCallbackCss,
      callbackUrl: hpfCallbackPageUrl
    }
    if (captchaData) {
      creditCardInitCommand.captchaResponse = {
        response: captchaData.response,
        ekey: captchaData.key,
        host: currentWindow.location.host,
        siteKey: captchaData.siteKey
      }
    }
    const { data } = await this.$api.cartsApi.creditCardInitialization(auth.sessionToken, cart.id, creditCardInitCommand)
    const response = { uid: data.uid }
    commit('state', response)
    return response
  },
  async checkout ({ dispatch, state, rootGetters, commit }, { auth, cart, hpfToken, consents, wallet }) {
    const { sessionToken } = auth
    const { email, firstName, lastName, address } = auth.customer

    const commonCommand = createCommonCheckoutCommand({ email, firstName, lastName, address }, rootGetters['auth/isAnonymous'])
    const paymentMethodId = wallet?.selectedPaymentMethodId
    const command = {
      ...commonCommand
    }
    if (hpfToken) {
      Object.assign(command, { uid: hpfToken })
    }
    if (consents) {
      Object.assign(command, { consents })
    }
    if (paymentMethodId) {
      Object.assign(command, { paymentMethodId })
    }
    if (rootGetters['cart/isProcessingFeeConsentGranted']) {
      await dispatch('cart/addProcessFeeToCart', {}, { root: true })
    }
    let order
    if (paymentMethodId) {
      const { data } = await this.$api.cartsApi.walletCheckout(sessionToken, cart.id, command, WITH_CREDENTIALS_OPTION)
      order = data
    } else {
      const { data } = await this.$api.cartsApi.hpfCheckout(sessionToken, cart.id, command, WITH_CREDENTIALS_OPTION)
      order = data
    }
    const checkoutOrderPayload = { ...order, cartItemSku: rootGetters['cart/cartItemSku'] }
    commit('order/state', checkoutOrderPayload, { root: true })
    return order
  }
}

export const mutations = {
  state (state, payload) {
    Object.assign(state, payload)
  },
  errors (state, payload) {
    state.errors = payload
  }
}

export default {
  namespaced: true,
  state: () => state,
  actions,
  mutations
}
