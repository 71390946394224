import ContributionAdapter from '@/adapters/ContributionAdapter'
import CRHeaderContentAdapter from '@/adapters/CRHeaderContentAdapter'
import CRFooterContentAdapter from '@/adapters/CRFooterContentAdapter'
import CRHeaderLogoAdapter from '@/adapters/CRHeaderLogoAdapter'
import ImageAdapter from '@/adapters/ImageAdapter'
import ContentProvider, { ContentModel } from '@/adaptersProviders/ContentProvider'

export default class Default2020Provider extends ContentProvider {
  getContentModel () {
    const model = new Default2020Model()
    super.defineBaseInfo(model)
    model.headerLogo = new CRHeaderLogoAdapter(this.cmsData['logo-image']).adapt()
    model.headerContent = new CRHeaderContentAdapter(this.cmsData['main-copy']).adapt()

    model.contributions = this.cmsData.contributions.map((cmsContr, index) => {
      return new ContributionAdapter(cmsContr, index).adapt()
    })
    model.footerContent = new CRFooterContentAdapter(this.cmsData).adapt()
    model.backgroundImage = new ImageAdapter(this.cmsData['background-image']).adapt()
    return model
  }
}

export class Default2020Model extends ContentModel {
  headerLogo
  headerContent
  contributions
  footerContent
  backgroundImage
}
