import { createCommonCheckoutCommand } from '@/store/checkoutUtils'
import { WITH_CREDENTIALS_OPTION } from '@/store/constants'
import { handleCheckoutError } from '@/helpers/braintree'
import { rethrowIfAuthError } from '@/helpers/globalErrorHelper'

export const state = {
  initData: {
    token: '',
    applePayPaymentRequest: {
      lineItems: [],
      total: {
        label: '',
        amount: '',
        type: ''
      },
      requiredBillingContactFields: []
    }
  },
  applePay: {
    nonce: '',
    paymentAuthorizedEvent: {}
  }
}
export const INVALID_TOKEN = 'INVALID_TOKEN'
const LOGGER_NAME = 'applePay.js'

export const actions = {
  async initApplePay ({ commit, rootGetters }) {
    try {
      const sessionToken = rootGetters['auth/sessionToken']
      const cartId = rootGetters['cart/cartId']
      const response = await this.$api.cartsApi.applePayInitialization(sessionToken, cartId)
      const { data } = response
      commit('initData', data)
      return data
    } catch (e) {
      rethrowIfAuthError(e)
      this.$logger.error(LOGGER_NAME, { error: e, info: 'initApplePay failed' })
      throw Object({ code: INVALID_TOKEN, data: 'Invalid Response' })
    }
  },
  async storeApplePayAuthData ({ commit }, { nonce, paymentAuthorizedEvent }) {
    commit('applePay', { nonce, paymentAuthorizedEvent })
  },
  async checkout ({ dispatch, state, rootGetters, commit }) {
    const sessionToken = rootGetters['auth/sessionToken']
    const cartId = rootGetters['cart/cartId']
    const { email, firstName, lastName, address } = rootGetters['auth/fullCustomer']

    const commonCommand = createCommonCheckoutCommand({ email, firstName, lastName, address }, rootGetters['auth/isAnonymous'])
    const command = {
      ...commonCommand,
      applePay: state.applePay
    }
    if (rootGetters['cart/isProcessingFeeConsentGranted']) {
      await dispatch('cart/addProcessFeeToCart', {}, { root: true })
    }
    try {
      const { data } = await this.$api.cartsApi.applePayCheckout(sessionToken, cartId, command, WITH_CREDENTIALS_OPTION)
      const checkoutOrderPayload = { ...data, cartItemSku: rootGetters['cart/cartItemSku'] }
      commit('order/state', checkoutOrderPayload, { root: true })
    } catch (e) {
      handleCheckoutError(e, { firstName, lastName, address }, this.$logger, LOGGER_NAME)
    }
  }
}

export const mutations = {
  initData (state, payload) {
    Object.assign(state.initData, payload)
  },
  applePay (state, payload) {
    Object.assign(state.applePay, payload)
  }
}

export default {
  namespaced: true,
  state: () => state,
  actions,
  mutations
}
