class StopWatch {
  /**
     * Internal property to track start time timestamp in milliseconds.
     * Initially equals to StopWatch creation time.
     * @type {number}
     */
  startTimeMillis = Date.now()

  /**
     * Set start timestamp to current date time
     * @return {number} startTimeMillis current date time.
     */
  start () {
    this.startTimeMillis = Date.now()
    return this.startTimeMillis
  }

  /**
     * Returns duration in milliseconds
     * @return {number} duration from startTimeMillis (currentDateTime - startTimeMillis).
     */
  stop () {
    return Date.now() - this.startTimeMillis
  }
}

export default StopWatch
