import Default2020Provider from './Default2020Provider'
import SingleProvider from './SingleProvider'

export default class ContentProviderFactory {
  findProvider ({ donationSpec }) {
    const template = donationSpec.metadata['template-name']
    if (template === 'Default-2020') {
      return new Default2020Provider(donationSpec)
    }
    if (template === 'Single') {
      return new SingleProvider(donationSpec)
    }
  }
}
