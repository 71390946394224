export class ForbiddenError extends Error {}

export class UnauthorizedError extends Error {}

export const rethrowIfAuthError = (error) => {
  if (isAuthError(error)) {
    throw error
  }
}

export const isAuthError = (error) => {
  return error instanceof ForbiddenError || error instanceof UnauthorizedError
}

export const isUnauthorizedError = (error) => {
  return error instanceof UnauthorizedError
}

export const isForbiddenError = (error) => {
  return error instanceof ForbiddenError
}

export const throwForbiddenError = () => {
  throw new ForbiddenError('Session token forbidden')
}

export const throwUnauthorizedError = () => {
  throw new UnauthorizedError('Session token unauthorized')
}

export const allSettledWithAuthErrorHandling = async (promises) => {
  const results = await Promise.allSettled(promises)
  const rejected = results.filter(result => result.status === 'rejected').map(result => result.reason)
  const authError = rejected.find(e => isAuthError(e))
  if (authError) {
    throw authError
  }
  return results
}
