import { urlParams } from '@/configuration'
import { ProductType } from '@/helpers/ProductType.constants'

export function toCartProduct (response) {
  const { data } = response
  const { metaData, ...product } = data
  const { MARKETING_BLURB_CODE } = metaData
  product.metadata = { marketingBlurb: MARKETING_BLURB_CODE }
  return product
}

export function toAddToCartCommand ({ donationSpecId, donationSpecRevision, sku, amount }) {
  return {
    items: [
      {
        quantity: 1,
        sku,
        amountOverride: amount
      }
    ],
    donationSpec: {
      id: donationSpecId,
      revision: donationSpecRevision
    }
  }
}

export const cartStateFn = () => ({
  cartItems: [
    {
      id: '',
      metaData: {},
      quantity: 0,
      sku: '',
      total: {
        amount: 0,
        currencyCode: '',
        prettyPrint: ''
      },
      product: {
        productType: ''
      }
    }
  ],
  hasRecurringItems: '',
  id: '',
  sku: '',
  total: {
    amount: 0,
    currencyCode: '',
    prettyPrint: ''
  },
  totalQuantity: 0,
  upgradeProcessed: false,
  product: {
    productCode: '',
    displayName: '',
    metadata: {
      marketingBlurb: ''
    }
  },
  processingFee: 0,
  isProcessingFeeConsentGranted: false,
  isProcessingFeeAddedToCart: false
})

export const actions = {
  async createCart ({ dispatch, commit, state, rootGetters }, { cartId, addToCartCommand }) {
    const sessionToken = rootGetters['auth/sessionToken']
    const cartCommand = { ...addToCartCommand, ...urlParams.keyCodes }
    let response
    if (cartId) {
      await this.$api.cartsApi.updateCartById(sessionToken, cartId, cartCommand)
      response = await this.$api.cartsApi.fetchCartById(sessionToken, cartId)
    } else {
      response = await this.$api.cartsApi.createCart(sessionToken, cartCommand)
      this.$donationSpec.metadata.processingFeeEnabled && commit('calculateProcessingFee', response.data.total.amount)
    }
    const { data } = response
    const [item] = addToCartCommand.items
    commit('state', { ...data, sku: item.sku })
    return data
  },
  async upgradeCart ({ state, dispatch, commit, getters, rootState }, addToCartCommand) {
    const cartId = getters.cartId
    await dispatch('createCart', { addToCartCommand, cartId })
    commit('upgradeProcessed', true)
    state.isProcessingFeeConsentGranted && commit('setIsProcessingFeeAddedToCart', true)
  },
  async addProcessFeeToCart ({ state, commit, dispatch, getters }) {
    // check if the cart was already upgraded with a new cart total including the processing fee
    if (state.isProcessingFeeAddedToCart) {
      return
    }
    const addToCartCommand = toAddToCartCommand({
      donationSpecId: this.$donationSpec.metadata.id,
      donationSpecRevision: this.$donationSpec.metadata.revision,
      sku: getters['cartItemSku'],
      amount: getters['cartTotalAmount']
    })
    await dispatch('createCart', {
      addToCartCommand,
      cartId: getters['cartId']
    })
    commit('setIsProcessingFeeAddedToCart', true)
  },
  cancelUpgradeCart ({ commit }) {
    commit('upgradeProcessed', true)
  },
  async product ({ commit, state, rootGetters }) {
    const sessionToken = rootGetters['auth/sessionToken']
    const response = await this.$api.productsApi.fetchProductByCode(sessionToken, state.sku)
    const product = toCartProduct(response)
    commit('product', product)
    return product
  },
  resetState ({ commit }) {
    commit('resetState')
  }
}

export const mutations = {
  state (state, payload) {
    Object.assign(state, payload)
  },
  product (state, payload) {
    Object.assign(state.product, payload)
  },
  upgradeProcessed (state, payload) {
    state.upgradeProcessed = payload
  },
  resetState (state) {
    Object.assign(state, cartStateFn())
  },
  cartId (state, id) {
    state.id = id
  },
  setIsProcessingFeeConsentGranted (state, payload) {
    state.isProcessingFeeConsentGranted = payload
  },
  setIsProcessingFeeAddedToCart (state, payload) {
    state.isProcessingFeeAddedToCart = payload
  },
  calculateProcessingFee (state, amount) {
    state.processingFee = Number((amount * 0.02).toFixed(2))
  }
}

export const getters = {
  cartItemSku (state) {
    return state.sku
  },
  currencyCode (state) {
    return state.total.currencyCode
  },
  cartTotalAmount (state) {
    if (state.isProcessingFeeConsentGranted && !state.isProcessingFeeAddedToCart) {
      return state.total.amount + state.processingFee
    }
    return state.total.amount
  },
  cartUpgradeProcessed (state) {
    return state.upgradeProcessed
  },
  cartId (state) {
    return state.id
  },
  product (state) {
    return state.product
  },
  cartItem (state) {
    return state.cartItems.find(({ sku }) => sku === state.sku)
  },
  cartItems (state) {
    return state.cartItems
  },
  donationItems (state) {
    return state.cartItems?.filter(cartItem => cartItem.product?.productType === ProductType.DONATION)
  },
  isEmpty (state) {
    return state.cartItems[0] == null || state.cartItems[0].quantity === 0
  },
  submitButtonText (state) {
    let totalAmount = state.total.amount
    const hasRecurringItems = state.hasRecurringItems
    let ending = ''
    if (hasRecurringItems) {
      ending = ' Monthly'
    }
    if (state.isProcessingFeeConsentGranted && !state.isProcessingFeeAddedToCart) {
      totalAmount = (totalAmount + state.processingFee).toFixed(2)
    }
    if (state.isProcessingFeeAddedToCart) {
      totalAmount = totalAmount.toFixed(2)
    }
    return `Donate $${totalAmount}${ending}`
  },
  processingFee (state) {
    return state.processingFee
  },
  isProcessingFeeConsentGranted (state) {
    return state.isProcessingFeeConsentGranted
  }
}

export default {
  namespaced: true,
  state: cartStateFn,
  actions,
  mutations,
  getters
}
