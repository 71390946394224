import { parseHandlebars } from '@/helpers'

export const contentStateFn = () => ({
  upgradeOverlay: {
    enabled: false,
    template: '',
    type: '',
    value: '',
    upgradeContent: {
      heading: '',
      bodyContent: '',
      upgradeActionText: '',
      noUpgradeActionText: '',
      image: {
        url: '',
        alt: ''
      }
    }
  }
})

export const actions = {
  setUpgradeOverlay ({ commit }, payload) {
    if (payload) {
      commit('upgradeOverlay', payload)
    } else {
      const { upgradeOverlay } = contentStateFn()
      commit('upgradeOverlay', upgradeOverlay)
    }
  }
}

export const mutations = {
  upgradeOverlay (state, payload) {
    Object.assign(state.upgradeOverlay, payload)
  },
  resetState (state) {
    Object.assign(state, contentStateFn())
  }
}

export const getters = {
  getUpgradeOverlayContent (state, getters) {
    const {
      heading,
      bodyContent,
      upgradeActionText,
      noUpgradeActionText,
      image
    } = state.upgradeOverlay.upgradeContent
    const messageContext = getters.getMessageContext
    return {
      heading: parseHandlebars(heading, messageContext),
      bodyContent: parseHandlebars(bodyContent, messageContext),
      upgradeActionText: parseHandlebars(upgradeActionText, messageContext),
      noUpgradeActionText: parseHandlebars(noUpgradeActionText, messageContext),
      image
    }
  },
  getMessageContext (state, getters, rootState, rootGetters) {
    const customer = rootGetters['auth/customer']
    const cartTotalAmount = rootGetters['cart/cartTotalAmount']
    const upgradeTotal = getters.getUpgradeAmount
    return {
      customer,
      cart: {
        total: cartTotalAmount,
        upgradeTotal
      }
    }
  },
  getUpgradeAmount (state, getters, rootState, rootGetters) {
    const { type, value } = state.upgradeOverlay
    const cartTotalAmount = rootGetters['cart/cartTotalAmount']
    if (!getters.isUpgradeShouldBeDone) {
      return cartTotalAmount
    }
    if (type === 'INCREASE_AMOUNT') {
      return Math.ceil(cartTotalAmount * value)
    }
    if (type === 'CHANGE_SKU') {
      return Math.max(Math.ceil(cartTotalAmount / 10), 5)
    }
    return cartTotalAmount
  },
  getUpgradeSkuCode (state, getters, rootState, rootGetters) {
    const { type, value } = state.upgradeOverlay
    return type === 'CHANGE_SKU' ? value : rootGetters['cart/cartItemSku']
  },
  getUpgradeComponent (state) {
    if (state.upgradeOverlay.template === 'Vertical-2021') {
      return 'UpgradeOverlayVertical2021'
    }
    if (state.upgradeOverlay.template === 'Horizontal-2021') {
      return 'UpgradeOverlayHorizontal2021'
    }
    return 'UpgradeOverlayHorizontal2021'
  },
  isUpgradeOverlayEnabled (state) {
    return state.upgradeOverlay.enabled
  },
  isUpgradeShouldBeDone (state, getters, rootState, rootGetters) {
    return getters.isUpgradeOverlayEnabled && !rootGetters['cart/cartUpgradeProcessed']
  },
  getUpgradeAdobeSCData (state) {
    const { type } = state.upgradeOverlay
    if (type === 'CHANGE_SKU') {
      return {
        viewName: 'ChangeSKU',
        events: 'event159'
      }
    }
    if (type === 'INCREASE_AMOUNT') {
      return {
        viewName: 'IncreaseAmount',
        events: 'event158'
      }
    }
    return {
      viewName: 'UNKNOWN',
      events: 'UNKNOWN'
    }
  }
}

export default {
  namespaced: true,
  state: contentStateFn,
  actions,
  mutations,
  getters
}
