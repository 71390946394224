import { browserPlatform, cookies } from '@/configuration'
import { currentWindow } from '@/plugins/windows'
import { isCsrImpersonation, applySuffixIfCsr } from '@/helpers/impersonationHelper'

/**
 * install $sc object on Vue.prototype
 */
const LOGGER_NAME = 'trackingAdobeSC.js'
export class Sc {
  constructor (s, logger, pageTemplateVersion) {
    this.s = s
    this.logger = logger
    this.pageTemplateVersion = pageTemplateVersion
  }

  trackView (scOptions) {
    const s = this.s
    if (s === undefined) {
      this.logger.error(LOGGER_NAME, { info: 'window.s is undefined during trackView' })
      return
    }
    s.pageName = 'CRO:Donate:Donation' + applySuffixIfCsr(scOptions.pageName)

    s.channel = 'cro'
    s.prop1 = 'Donate'
    s.prop2 = 'Donation' + scOptions.pageName
    s.prop9 = s.eVar16 = scOptions.prop9 ?? 'ecom'
    s.prop72 = this.pageTemplateVersion
    s.products = ';crod'

    s.events = isCsrImpersonation() ? 'event160,' + scOptions.events : scOptions.events

    if (cookies.userInfo.tier != null) { s.prop27 = s.eVar80 = cookies.userInfo.tier.toLowerCase() } else { s.prop27 = s.eVar80 = 'anonymous' }

    const deviceType = browserPlatform.deviceType
    s.prop58 = deviceType
    s.eVar58 = deviceType
    s.prop16 = cookies.memberType.toLowerCase()
    if (scOptions.eVar97) {
      s.eVar97 = scOptions.eVar97
    }
    s.t()
  }

  trackViewTransient (viewName) {
    const s = this.s
    if (s === undefined) {
      this.logger.error(LOGGER_NAME, { info: 'window.s is undefined during trackViewTransient' })
      return
    }
    const transientTrackData = {}
    transientTrackData.pageName = 'CRO:Donate:Donation' + viewName
    transientTrackData.prop2 = 'Donation' + viewName
    s.t(transientTrackData)
  }

  trackLink (linkName, { amount, events, viewName } = {}) {
    const s = this.s
    if (s === undefined) {
      this.logger.error(LOGGER_NAME, { info: 'window.s is undefined during trackLink' })
      return
    }
    let prop48 = linkName
    if (amount !== undefined) {
      prop48 += '_$' + amount
    }
    prop48 = `${s.pageName} | ${prop48}`

    const transientTrackData = {}
    transientTrackData.prop48 = prop48
    transientTrackData.eVar48 = prop48
    if (events !== undefined) {
      transientTrackData.linkTrackEvents = transientTrackData.events = events
    }
    if (viewName !== undefined) {
      transientTrackData.pageName = 'CRO:Donate:Donation' + viewName
      transientTrackData.prop2 = 'Donation' + viewName
    }
    s.linkTrackVars = 'events,products,prop48,eVar48,prop72,eVar72,prop58,eVar58'
    if (s.tl === undefined) {
      this.logger.error(LOGGER_NAME, { info: 's.tl is undefined during trackLink' })
      return
    }
    s.tl(this, 'o', linkName, transientTrackData)
  }
}

export const trackAfterEachRoute = ({ store, sc }) => (to) => {
  const route = to.matched.filter(record => record.meta.trackingAdobeSC).slice(-1).pop()
  if (route) {
    route.meta.trackingAdobeSC.forEach((trackFn) => {
      trackFn({ route, store, sc })
    })
  }
}

const plugin = {
  install (Vue, { router, store, logger, pageTemplateVersion }) {
    if (plugin.installed) {
      return
    }
    const sc = new Sc(currentWindow.s, logger, pageTemplateVersion)
    Vue.prototype.$sc = sc
    router.afterEach(trackAfterEachRoute({ sc, store }))
  }
}
export default plugin
