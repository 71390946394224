export default class AskAmountFixedInputAdapter {
  constructor (cmsData, index) {
    this.cmsData = cmsData
    this.index = index
  }

  adapt () {
    const model = new AskAmountFixedInputModel()
    model.label = this.cmsData.label
    model.value = this.cmsData.value
    // model.hint = '<strong>Wow,</strong> <i>you are</i> giving that much &#x1F42A; &#x1F49A; &#x1F354; &#128525;'
    model.hint = this.cmsData.hint
    model.index = this.index
    return model
  }
}

export class AskAmountFixedInputModel {
  index
  label
  value
}
