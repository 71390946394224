import { cookies } from '@/configuration'
import { ERROR_EVENT, eventBus, SEND_METRIC } from '@/plugins/eventBus'
import Newrelic from '@/plugins/newrelic/newrelic'

export const $newrelic = new Newrelic()

const errorEventHandler = (newrelic) => ({ name, payload }) => {
  const { error, ...otherPayload } = payload
  const attributes = { loggerName: name, feSessionToken: cookies.feSessionToken, ...otherPayload }
  newrelic.noticeError(error, attributes)
}

const metricHandler = (newrelic) => ({ name, ...payload }) => {
  const attributes = { ...payload, event: 'metric' }
  newrelic.addPageAction(name, attributes)
}

/**
 * register $newrelic plugin to Vue and Vuex store instances
 * @type {{install(*, {router: *, store: *}): (undefined)}}
 */
const plugin = {
  install (Vue, { store }) {
    if (plugin.installed) {
      return
    }
    Vue.prototype.$newrelic = $newrelic
    store.$newrelic = $newrelic
    eventBus.$on(ERROR_EVENT, errorEventHandler($newrelic))
    eventBus.$on(SEND_METRIC, metricHandler($newrelic))
  }
}
export default plugin
