import { assert } from '@/helpers'
import { ERROR_EVENT, eventBus } from '@/plugins/eventBus'
const beaverLogger = require('beaver-logger')

export const generatePayload = function ({ error, vm, info = '', ...others }) {
  const invalidParameters = Object.keys(others)
  assert(invalidParameters.length === 0, 'invalid parameters: ' + invalidParameters.join(', '))
  const payload = {}
  if (error instanceof Error) {
    payload.error = error.stack
    const { response, request } = error
    if (response) {
      payload.response = response.data
    }
    if (request) {
      payload.request = { url: request.url }
    }
  }
  if (info) {
    payload.info = info
  }
  if (vm && vm.$options.name) {
    payload.component = vm.$options.name
  }
  return payload
}

export class Logger {
  constructor (logger) {
    this.logger = logger
  }

  info (name, payload) {
    this.log('info', name, payload)
  }

  warn (name, payload) {
    this.log('warn', name, payload)
  }

  error (name, payload, emit = true) {
    if (payload.error instanceof Error && emit) {
      eventBus.$emit(ERROR_EVENT, { name, payload })
    }
    this.log('error', name, payload)
  }

  debug (name, payload) {
    this.log('debug', name, payload)
  }

  log (level, name, payload) {
    const method = this.logger[level]
    const normalizedPayload = generatePayload(payload)
    method.apply(this.logger, [name, normalizedPayload])
  }
}

export const initLogger = ({ appConfig }) => {
  const url = appConfig.baseUrl + appConfig.beaver.url
  const options = { ...appConfig.beaver, url }
  return new Logger(beaverLogger.Logger(options))
}

const plugin = {
  install (Vue, logger) {
    if (plugin.installed) {
      return
    }
    plugin.installed = true
    Vue.prototype.$logger = logger
  }
}
export default plugin

export class LoggerFactory {
  static instance
  static configuration

  constructor () {
    throw new Error('Cannot instantiate singleton class directly')
  }

  static getInstance () {
    if (!LoggerFactory.instance) {
      if (!LoggerFactory.configuration) {
        throw new Error('Configuration for LoggerFactory is not set')
      }
      const config = LoggerFactory.configuration
      LoggerFactory.instance = initLogger(config)
    }
    return LoggerFactory.instance
  }

  static setConfigSource (configuration) {
    LoggerFactory.configuration = configuration
  }
}
