export default class StoreStateContentModelAdapter {
  constructor (store, donationSpec) {
    this.store = store
    this.donationSpec = donationSpec
  }

  restoreContributionState () {
    const { contributions } = this.donationSpec
    const cartItemSku = this.store.getters['cart/cartItemSku']

    if (cartItemSku) {
      const selectedContribution = contributions.find(({ contributionFrequency: { skuCode } }) => skuCode === cartItemSku)
      if (selectedContribution) {
        const n = contributions.length
        for (let i = 0; i < n; i++) {
          contributions[i].contributionFrequency.selected = false
        }
        selectedContribution.contributionFrequency.selected = true
        this.restoreAmountState(selectedContribution)
      }
    }
  }

  restoreAmountState (contribution) {
    const cartTotalAmount = this.store.getters['cart/cartTotalAmount']
    if (cartTotalAmount && contribution.askContribution?.amounts) {
      const selectedAmount = contribution.askContribution.amounts.find(({ value }) => {
        return Number(value) === cartTotalAmount
      })
      if (selectedAmount) {
        contribution.askContribution.selectedAmountIndex = selectedAmount.index
      } else {
        // selectedAmountIndex = 6 means that we want to select "other" input (free form Input)
        const otherAmount = contribution.askContribution.amounts[5]
        otherAmount.value = cartTotalAmount
        contribution.askContribution.selectedAmountIndex = otherAmount.index
      }
    }
  }

  adapt () {
    this.restoreContributionState()
    return this.donationSpec
  }
}
