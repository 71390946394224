import { WITH_CREDENTIALS_OPTION } from '@/store/constants'
import { createCommonCheckoutCommand } from '@/store/checkoutUtils'
import { INVALID_BILLING_ADDRESS, PURCHASE_EXCEPTION } from '@/helpers/braintree'
import { rethrowIfAuthError } from '@/helpers/globalErrorHelper'
const LOGGER_NAME = 'amazonPay.js'

export function errorToCustomer (error) {
  const city = error.address.city
  const country = error.address.country
  const address = error.address.streetAddress
  const stateR = error.address.state
  const zip = error.address.zipCode
  const firstName = error.address.firstName
  const lastName = error.address.lastName
  const customerAddress = { city, country, address, state: stateR, zip }
  return { firstName, lastName, address: customerAddress }
}
export const state = {
  amazonPay: {
    accessToken: '',
    billingAgreementId: '',
    orderReferenceId: ''

  }
}
export const actions = {
  saveAmazonDetailsToStore ({ dispatch, state, commit }, { accessToken, orderReferenceId, billingAgreementId }) {
    const amazonPay = { accessToken, orderReferenceId, billingAgreementId }
    commit('amazonPay', amazonPay)
  },

  async checkout ({ dispatch, state, rootGetters, commit }, { auth, cart }) {
    const { sessionToken } = auth
    const { email, firstName, lastName, address } = auth.customer

    const commonCommand = createCommonCheckoutCommand({ email, firstName, lastName, address }, rootGetters['auth/isAnonymous'])
    const { accessToken, billingAgreementId, orderReferenceId } = state.amazonPay
    const command = {
      ...commonCommand,
      amazonPay: { accessToken, billingAgreementId, orderReferenceId }
    }
    try {
      const { data } = await this.$api.cartsApi.amazonPayCheckout(sessionToken, cart.id, command, WITH_CREDENTIALS_OPTION)
      const checkoutOrderPayload = { ...data, cartItemSku: rootGetters['cart/cartItemSku'] }
      commit('order/state', checkoutOrderPayload, { root: true })
      return data
    } catch (e) {
      rethrowIfAuthError(e)
      this.$logger.error(LOGGER_NAME, { error: e })
      const { response } = e
      if (response.status === 400) {
        if (response.data.errors[0].data != null) {
          throw Object({
            code: INVALID_BILLING_ADDRESS, data: errorToCustomer(response.data.errors[0].data)
          })
        } else {
          throw Object({
            code: INVALID_BILLING_ADDRESS, data: { firstName, lastName, address }
          })
        }
      } else {
        throw Object({
          code: PURCHASE_EXCEPTION, data: 'Purchase failed'
        })
      }
    }
  }
}

export const mutations = {
  state (state, payload) {
    Object.assign(state, payload)
  },
  amazonPay (state, payload) {
    Object.assign(state.amazonPay, payload)
  }
}

export default {
  namespaced: true,
  state: () => state,
  actions,
  mutations
}
