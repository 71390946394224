import {
  ProductAffiliation,
  ProductCategory
} from './constants'
import { assert } from '@/helpers'
import {
  MONTHLY_CONTRIBUTION_SKU_REGEXP,
  MONTHLY_SKU_CODE_NAME,
  ONE_TIME_SKU_CODE_NAME,
  ONETIME_CONTRIBUTION_SKU_REGEXP
} from '@/helpers/skuUtils'

const convertSkuCodeToGtmGA4ItemName = (skuCode) => {
  assert(skuCode != null, 'cart sku item cannot be null')
  if (MONTHLY_CONTRIBUTION_SKU_REGEXP.test(skuCode)) {
    return MONTHLY_SKU_CODE_NAME
  } else if (ONETIME_CONTRIBUTION_SKU_REGEXP.test(skuCode)) {
    return ONE_TIME_SKU_CODE_NAME
  }
}
export const convertDonationItemToGtmEcommerceItem = item => {
  return {
    item_id: item.sku, // Example DONATION-5-FUNDS2
    item_name: convertSkuCodeToGtmGA4ItemName(item.sku),
    affiliation: ProductAffiliation.FUNDRAISING,
    item_category: ProductCategory.DONATION,
    price: item.total.amount
  }
}
