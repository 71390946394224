export default class QueryParamProcessingFeeContentModelAdapter {
  constructor (urlParams, donationSpec) {
    this.urlParams = urlParams
    this.donationSpec = donationSpec
  }

  updateProcessingFeeEnabled (options) {
    this.donationSpec.metadata.processingFeeEnabled = options.isProcessingFeeEnabled || this.donationSpec.metadata.processingFeeEnabled
  }

  adapt () {
    this.updateProcessingFeeEnabled(this.urlParams)
    return this.donationSpec
  }
}
