export default class CRFooterContentAdapter {
  constructor (cmsData) {
    this.cmsData = cmsData
  }

  adapt () {
    const model = new CRFooterModel()
    model.message = this.cmsData['footer-message']
    return model
  }
}

export class CRFooterModel {
  message
}
