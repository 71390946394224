import { assert } from '@/helpers'

const memberCardTypeComponents = {
  fundraising: 'MemberCardRenewalFR',
  'leadership-circle': 'MemberCardRenewalLC'
}
export default class CRHeaderContentAdapter {
  constructor (cmsData) {
    this.cmsData = cmsData
  }

  adapt () {
    const model = new CRHeaderContentModel()
    model.heading = this.cmsData.heading
    model.content = this.cmsData.body
    model.memberCardType = this.cmsData['member-card-type']
    if (model.memberCardType) {
      model.memberCardTypeComponent = memberCardTypeComponents[model.memberCardType.toLowerCase()]
      assert(model.memberCardTypeComponent, 'unsupported member card type: ' + model.memberCardType)
    }
    return model
  }
}

export class CRHeaderContentModel {
  heading
  content
  memberCardType
  memberCardTypeComponent
}
