import ContentProviderFactory from '@/adaptersProviders/ContentProviderFactory'
import QueryParamContentModelAdapter from '@/adapters/QueryParamContentModelAdapter'
import StoreStateContentModelAdapter from '@/adapters/StoreStateContentModelAdapter'
import QueryParamProcessingFeeContentModelAdapter from '@/adapters/QueryParamProcessingFeeContentModelAdapter'

const plugin = {
  install (Vue, options) {
    if (plugin.installed) {
      return
    }
    let contentModel = new ContentProviderFactory().findProvider(options).getContentModel()
    contentModel = new QueryParamContentModelAdapter(options.urlParams.queryParams, contentModel).adapt()
    contentModel = new StoreStateContentModelAdapter(options.store, contentModel).adapt()
    contentModel = new QueryParamProcessingFeeContentModelAdapter(options.urlParams, contentModel).adapt()
    Vue.prototype.$donationSpec = Object.freeze(contentModel)
    plugin.installed = true
  }
}
export default plugin
