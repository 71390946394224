import { urlParams } from '@/configuration'

function toCommandAddress (cartAddress) {
  const {
    city,
    country,
    address2: extStreet,
    state,
    address: streetAddress,
    zip: zipCode
  } = cartAddress
  return {
    streetAddress, extStreet, zipCode, state, city, country
  }
}

export const createCommonCheckoutCommand = ({ email, firstName, lastName, address }, isAnonymous) => {
  const customer = { email, firstName, lastName }
  if (address?.address?.length > 0) {
    customer.address = toCommandAddress(address)
  }
  const commonCommand = {
    customer,
    sourceSystem: 'EC_DONATION_FLOW_ORDER',
    restrictCookies: true
  }
  if (isAnonymous && urlParams.hasAttributionCode) {
    commonCommand.attributionCode = urlParams.attributionCode
  }
  return commonCommand
}
