export default {
  methods: {
    async showLoader () {
      await this.$store.dispatch('progressLoader/show')
    },
    async hideLoader () {
      await this.$store.dispatch('progressLoader/hide')
    },
    async pauseLoader () {
      await this.$store.dispatch('progressLoader/pause')
    },
    async resumeLoader () {
      await this.$store.dispatch('progressLoader/resume')
    },
    resetLoader () {
      this.$store.commit('progressLoader/resetState')
    }
  },
  computed: {
    isLoaderVisible () {
      return this.$store.getters['progressLoader/isVisible']
    }
  }
}
