import ContributionAdapter from '@/adapters/ContributionAdapter'
import CRFooterContentAdapter from '@/adapters/CRFooterContentAdapter'
import CRHeaderLogoAdapter from '@/adapters/CRHeaderLogoAdapter'
import ContentProvider, { ContentModel } from '@/adaptersProviders/ContentProvider'
import HeroAdapter from '@/adapters/HeroAdapter'

export default class SingleProvider extends ContentProvider {
  getContentModel () {
    const model = new SingleModel()
    // TODO we might want to extract common code from here
    super.defineBaseInfo(model)
    model.headerLogo = new CRHeaderLogoAdapter(this.cmsData['logo-image']).adapt()
    model.hero = new HeroAdapter(this.cmsData.hero).adapt()
    model.contributions = this.cmsData.contributions.map((cmsContr, index) => {
      return new ContributionAdapter(cmsContr, index).adapt()
    })
    model.footerContent = new CRFooterContentAdapter(this.cmsData).adapt()
    return model
  }
}

export class SingleModel extends ContentModel {
  headerLogo
  hero
  contributions
  footerContent
}
