import { assert } from '@/helpers'
import QueryParamAskAmountFixedInputAdapter, {
  QueryParamAmountsAdapter,
  QueryParamMonthlyAskAmountOptionAdapter,
  QueryParamOneTimeAskAmountOptionAdapter
} from '@/adapters/QueryParamAskAmountFixedInputAdapter'
import between from 'vuelidate/lib/validators/between'
import AskAmountOtherInputAdapter from '@/adapters/AskAmountOtherInputAdapter'
import {
  MONTHLY_CONTRIBUTION_SKU_REGEXP,
  ONETIME_CONTRIBUTION_SKU_REGEXP
} from '@/helpers/skuUtils'
import { QueryParamFrequencySelectedAdapter } from '@/adapters/QueryParamFrequencySelectedAdapter'

export const findMonthlyContribution = (donationSpec) => {
  return findContributionMatchingAnySkuCode(donationSpec, MONTHLY_CONTRIBUTION_SKU_REGEXP)
}
export const findOneTimeContribution = (donationSpec) => {
  return findContributionMatchingAnySkuCode(donationSpec, ONETIME_CONTRIBUTION_SKU_REGEXP)
}

export const findContributionMatchingAnySkuCode = (donationSpec, regexp) => {
  return donationSpec.contributions.find(({ contributionFrequency }) => {
    const skuCode = contributionFrequency.skuCode.toUpperCase()
    return regexp.test(skuCode)
  })
}

export const findAskContributionSelectedIndex = (askContribution) => {
  return askContribution.selectedAmountIndex
}

const MIN_INDEX_FREQUENCY_NUMBER = 1
const MIN_INDEX_FREQUENCY_OFFSET = 1
export default class QueryParamContentModelAdapter {
  constructor (urlParams, donationSpec) {
    this.urlParams = urlParams
    this.donationSpec = donationSpec
  }

  updateContributionAmountForOneTimeDonation (options) {
    if (!Object.keys(options).length) return
    const contribution = findOneTimeContribution(this.donationSpec)
    assert(contribution, `no contribution with skuCodes matching the regexp pattern ${ONETIME_CONTRIBUTION_SKU_REGEXP}`)
    this.updateContributionAmount(contribution, options)
  }

  updateContributionAmountForMonthlyDonation (options) {
    if (!Object.keys(options).length) return
    const contribution = findMonthlyContribution(this.donationSpec)
    assert(contribution, `no contribution with skuCodes matching the regexp pattern ${MONTHLY_CONTRIBUTION_SKU_REGEXP}`)
    this.updateContributionAmount(contribution, options)
  }

  updateContributionAmount (contribution, options) {
    const { askContribution, contributionFrequency } = contribution
    if (typeof options.showMemberBenefits === 'boolean') {
      contributionFrequency.showMemberBenefits = options.showMemberBenefits
    }
    let selected = options.selected
    if (!isNaN(selected) && between(1, 6)(selected)) {
      selected -= 1
      askContribution.selectedAmountIndex = selected
    }

    if (options.showMemberBenefits === 'true') {
      askContribution.showMemberBenefits = true
    }

    const amounts = new QueryParamAmountsAdapter(options).adapt()
    if (amounts.length === 5) {
      askContribution.amounts = new QueryParamAskAmountFixedInputAdapter({
        amounts,
        selected,
        cmsAmounts: askContribution.amounts
      }).adapt()
      // Add 'other' field
      const otherField = new AskAmountOtherInputAdapter({
        label: 'other',
        value: ''
      }, askContribution.amounts.length).adapt()
      askContribution.amounts.push(otherField)
    }
  }

  updateContributionFrequencySelected (options) {
    if (!options.frequencySelected) return
    const { contributions } = this.donationSpec
    if (options.frequencySelected > contributions.length || options.frequencySelected < MIN_INDEX_FREQUENCY_NUMBER) {
      return
    }
    contributions.forEach(({ contributionFrequency }, index) => {
      contributionFrequency.selected = (index === options.frequencySelected - MIN_INDEX_FREQUENCY_OFFSET)
    })
  }

  adapt () {
    const monthlyOptions = new QueryParamMonthlyAskAmountOptionAdapter(this.urlParams).adapt()
    const oneTimeOptions = new QueryParamOneTimeAskAmountOptionAdapter(this.urlParams).adapt()
    const frequencySelectedOptions = new QueryParamFrequencySelectedAdapter(this.urlParams).adapt()
    this.updateContributionAmountForOneTimeDonation(oneTimeOptions)
    this.updateContributionAmountForMonthlyDonation(monthlyOptions)
    this.updateContributionFrequencySelected(frequencySelectedOptions)
    return this.donationSpec
  }
}
