export const WITH_CREDENTIALS_OPTION = { withCredentials: true }

export const KEY_CODE_URL_MAPPING = {
  externalKey: 'EXTKEY',
  internalKey: 'INTKEY'
}

export const CAMPAIGN_URL_MAPPING = { campaignKey: 'campaign_key', externalReferenceCode: 'reference_code' }

export const SESSION_TYPE_REGISTERED = 'registered'
export const SESSION_TYPE_ANONYMOUS = 'anonymous'

export const PROCESSING_FEE_ENABLED = 'processing_fee_enabled'

export const ATTRIBUTION_CODE = 'attribution_code'
