import {
  Configuration,
  AuthenticationApi,
  CartsApi,
  CustomersApi,
  CountriesApi,
  StateCityApi,
  EmailsApi,
  ProductsApi,
  CampaignsApi,
  WalletApi
} from '@ec/ecommerce-api'
import axios from 'axios'
import { throwForbiddenError, throwUnauthorizedError } from '@/helpers/globalErrorHelper'
import { cookies } from '@/configuration'
import { isCsrImpersonation } from '@/helpers/impersonationHelper'

export const createApi = function (options, client = axios) {
  const configuration = new Configuration(options)
  const api = {
    authenticationApi: new AuthenticationApi(configuration, null, client),
    cartsApi: new CartsApi(configuration, null, client),
    customersApi: new CustomersApi(configuration, null, client),
    countriesApi: new CountriesApi(configuration, null, client),
    campaignsApi: new CampaignsApi(configuration, null, client),
    stateCityApi: new StateCityApi(configuration, null, client),
    emailsApi: new EmailsApi(configuration, null, client),
    productsApi: new ProductsApi(configuration, null, client),
    walletApi: new WalletApi(configuration, null, client)
  }

  client.interceptors.response.use(response => {
    return response
  }, error => {
    if (error.response?.status === 403) {
      throwForbiddenError()
    }
    if (error.response?.status === 401) {
      throwUnauthorizedError()
    }
    return Promise.reject(error)
  })
  return api
}

const plugin = {
  install (Vue, { appConfig }) {
    if (plugin.installed) {
      return
    }
    const config = { basePath: appConfig.ecommApiUrl }
    plugin.installed = true
    axios.defaults.headers.common['X-API-KEY'] = appConfig.ecommApiAuthKey
    axios.defaults.headers.post['Content-Type'] = 'application/json'
    if (isCsrImpersonation()) {
      config.basePath += '/csr'
      axios.defaults.headers.common['X-Run-As-ID'] = cookies.csrImpersonatedUserGuid
    }

    Vue.prototype.$api = createApi(config, axios)
  }
}
export default plugin
