import { browserPlatform, cookies } from '@/configuration'
import { currentWindow } from '@/plugins/windows'

export class Gtm {
  constructor (win = currentWindow) {
    win.dataLayer = win.dataLayer || []
    this.dataLayer = win.dataLayer
  }

  trackView (layer) {
    const siteLayout = browserPlatform.deviceType
    const segment = cookies.memberType
    const globalOptions = {
      event: 'viewChanged',
      pageType: '',
      siteLayout,
      segment,
      siteSectionL2: 'CRO',
      siteSectionL3: 'Fundraising',
      siteSectionL4: 'Donation',
      orderStep: ''
    }
    const payload = { ...globalOptions, ...layer }
    this.dataLayer.push(payload)
  }
}

export const gtm = new Gtm()

export const trackAfterEachRoute = ({ store, gtm }) => (to) => {
  const route = to.matched.filter(record => record.meta.trackingGtm).slice(-1).pop()
  if (route) {
    route.meta.trackingGtm.forEach((trackFn) => {
      trackFn({ route, store, gtm })
    })
  }
}

/**
 * register $gtm and $sc plugin
 * @type {{install(*, {router: *, store: *}): (undefined)}}
 */
const plugin = {
  install (Vue, { router, store }) {
    if (plugin.installed) {
      return
    }
    Vue.prototype.$gtm = gtm
    router.afterEach(trackAfterEachRoute({ gtm, store }))
  }
}
export default plugin
