import MetadataAdapter from '@/adapters/MetadataAdapter'

export default class ContentProvider {
  constructor (cmsData) {
    this.cmsData = cmsData
  }

  defineBaseInfo (model) {
    model.metadata = new MetadataAdapter(this.cmsData['metadata']).adapt()
    return model
  }
}

export class ContentModel {
  metadata
  get id () {
    return this.metadata.id
  }

  get templatePath () {
    const { templateName, id } = this.metadata
    return `/donation/${id}/${templateName}`
  }
}
