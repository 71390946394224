import { WITH_CREDENTIALS_OPTION } from '@/store/constants'
import { createCommonCheckoutCommand } from '@/store/checkoutUtils'
import { handleCheckoutError } from '@/helpers/braintree'
import { rethrowIfAuthError } from '@/helpers/globalErrorHelper'

export function errorToCustomer (error) {
  const city = error.address.city
  const country = error.address.country
  const address = error.address.streetAddress
  const stateR = error.address.state
  const zip = error.address.zipCode
  const firstName = error.address.firstName
  const lastName = error.address.lastName
  const customerAddress = { city, country, address, state: stateR, zip }
  return { firstName, lastName, address: customerAddress }
}
export const state = {
  paypal: {
    description: '',
    token: '',
    nonce: '',
    deviceData: '',
    details: ''
  }
}
export const INVALID_TOKEN = 'INVALID_TOKEN'
const LOGGER_NAME = 'paypal.js'

export const actions = {
  async initPaypal ({ commit, rootState }) {
    try {
      const { data } = await this.$api.cartsApi.payPalInitialization(rootState.auth.sessionToken, rootState.cart.id)
      commit('paypal', data)
      return data
    } catch (e) {
      rethrowIfAuthError(e)
      this.$logger.error(LOGGER_NAME, { error: e, info: 'initPaypal failed' })
      throw Object({ code: INVALID_TOKEN, data: 'Invalid Response' })
    }
  },
  paypalCartDetails ({ dispatch, state, commit }, { payload, braintreeDeviceData }) {
    const nonce = payload.nonce
    const deviceData = braintreeDeviceData
    const details = JSON.stringify(payload.details)
    const paypal = { nonce, deviceData, details }
    commit('paypal', paypal)
  },

  async checkout ({ dispatch, state, rootGetters, commit }, { auth, cart }) {
    const { sessionToken } = auth
    const { email, firstName, lastName, address } = auth.customer

    const commonCommand = createCommonCheckoutCommand({ email, firstName, lastName, address }, rootGetters['auth/isAnonymous'])
    const { details, deviceData, nonce } = state.paypal
    const command = {
      ...commonCommand,
      payPal: { details, deviceData, nonce }
    }
    if (rootGetters['cart/isProcessingFeeConsentGranted']) {
      await dispatch('cart/addProcessFeeToCart', {}, { root: true })
    }
    try {
      const { data } = await this.$api.cartsApi.paypalCheckout(sessionToken, cart.id, command, WITH_CREDENTIALS_OPTION)
      const checkoutOrderPayload = { ...data, cartItemSku: rootGetters['cart/cartItemSku'] }
      commit('order/state', checkoutOrderPayload, { root: true })
      return data
    } catch (e) {
      handleCheckoutError(e, { firstName, lastName, address }, this.$logger, LOGGER_NAME)
    }
  }
}

export const mutations = {
  state (state, payload) {
    Object.assign(state, payload)
  },
  errors (state, payload) {
    state.errors = payload
  },
  address (state, payload) {
    Object.assign(state.customer.address, payload)
  },
  paypal (state, payload) {
    Object.assign(state.paypal, payload)
  }
}

export default {
  namespaced: true,
  state: () => state,
  actions,
  mutations
}
