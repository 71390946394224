/* eslint-disable camelcase */
import { AskAmountFixedInputModel } from '@/adapters/AskAmountFixedInputAdapter'
import { mapObjectProperties, XOR } from '@/helpers'

export class QueryParamOneTimeAskAmountOptionAdapter {
  constructor (urlParams) {
    this.urlParams = urlParams
    this.optionMap = {
      ask: 'one_time_ask',
      askInc: 'one_time_ask_inc',
      askStart: 'one_time_ask_start',
      selected: 'one_time_ask_selected',
      showMemberBenefits: 'one_time_show_benefits'
    }
  }

  adapt () {
    return mapObjectProperties(this.urlParams, this.optionMap)
  }
}

export class QueryParamMonthlyAskAmountOptionAdapter {
  constructor (urlParams) {
    this.urlParams = urlParams
    this.optionMap = {
      ask: 'monthly_ask',
      askStart: 'monthly_ask_start',
      askInc: 'monthly_ask_inc',
      selected: 'monthly_ask_selected',
      showMemberBenefits: 'monthly_show_benefits'
    }
  }

  adapt () {
    return mapObjectProperties(this.urlParams, this.optionMap)
  }
}

export class QueryParamAmountsAdapter {
  constructor ({ ask, askStart, askInc }) {
    this.ask = ask
    this.askStart = askStart
    this.askInc = askInc
  }

  adapt () {
    if (!XOR(this.ask, this.askInc) || !XOR(this.ask, this.askStart)) {
      return []
    }
    const askAmounts = this.ask || this.askInc
    const increase = this.askStart || 1
    const splitAmounts = askAmounts.split('|').map(amount => Math.ceil((amount * increase).toFixed(8)))
    const isInvalidAmount = (amount) => isNaN(amount) || amount < 1 || amount > 10000

    if (splitAmounts.length !== 5) return []
    if (splitAmounts.some(isInvalidAmount)) return []
    return splitAmounts
  }
}

export default class QueryParamAskAmountFixedInputAdapter {
  constructor ({ amounts, cmsAmounts }) {
    this.askAmounts = amounts
    this.cmsAmounts = cmsAmounts
  }

  adapt () {
    return this.askAmounts.map((askAmount, index) => {
      const amountModel = new AskAmountFixedInputModel()
      amountModel.label = '$' + askAmount
      amountModel.value = askAmount
      amountModel.index = index
      if (this.cmsAmounts[index].hint !== undefined) {
        // hint is carried over from the cms amount
        amountModel.hint = this.cmsAmounts[index].hint
      }
      return amountModel
    })
  }
}
