import { currentWindow } from '@/plugins/windows'

/**
 * install $monetate object on Vue.prototype
 */

export class Monetate {
  constructor (win = currentWindow) {
    win.monetateQ = win.monetateQ || []
    this.window = win
  }

  trackView (pageMap) {
    this.pushSetPageType(pageMap)
    this.pushSetCustomVariable(pageMap)
    if (pageMap.cartRow) {
      this.pushAddCartRows(pageMap)
    }

    if (pageMap.orderRow) {
      this.pushPurchaseRows(pageMap)
    }
    this.window.monetateQ.push(['trackData'])
  }

  pushSetPageType (pageMap) {
    this.window.monetateQ.push(['setPageType', pageMap.pType])
  }

  pushSetCustomVariable (pageMap) {
    this.window.monetateQ.push(['setCustomVariables',
      [
        { name: 'membershipTier', value: pageMap.membershipTier }
      ]
    ])
  }

  pushAddCartRows (pageMap) {
    this.window.monetateQ.push([
      'addCartRows', pageMap.cartRow
    ])
  }

  pushPurchaseRows (pageMap) {
    this.window.monetateQ.push([
      'addPurchaseRows', pageMap.orderRow
    ])
  }
}

export const monetate = new Monetate()

export const trackAfterEachRoute = ({ store, monetate }) => (to) => {
  const route = to.matched.filter(record => record.meta.trackingMonetate).slice(-1).pop()
  if (route) {
    route.meta.trackingMonetate.forEach((trackFn) => {
      trackFn({ route, store, monetate })
    })
  }
}

const plugin = {
  install (Vue, { router, store }) {
    if (plugin.installed) {
      return
    }
    Vue.prototype.$monetate = monetate
    router.afterEach(trackAfterEachRoute({ monetate, store }))
  }
}
export default plugin
